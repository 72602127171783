<template>
  <div class="w-100 mb-3">
    <b-button
      v-b-toggle="`collapse-info`"
      class="collapse-card d-flex justify-content-between bg-white text-dark bg-white p-4 w-100 rounded"
      @click="visible = !visible"
    >
      <div class="d-flex flex-column text-left w-100">
        <div class="d-flex">
          <b-img-lazy
            class="d-none d-md-block"
            height="48"
            width="48"
            :src="require('@/assets/images/SparkMembers/Contents/views/Lives/grey-rocket.svg')"
          />
          <div class="d-flex flex-column ml-2 ml-md-3">
            <h5 class="font-weight-bold mb-1">
              {{ $t('sparkmembers.contents.views.lives.accordion-informative.title') }}
            </h5>
            <h5 class="font-weight-normal">
              {{ $t('sparkmembers.contents.views.lives.accordion-informative.subtitle') }}
            </h5>
          </div>
        </div>
      </div>
      <hs-icon variant="light" :icon="visible ? 'chevron-up' : 'chevron-down'" />
    </b-button>
    <b-collapse id="collapse-info" v-model="visible">
      <b-card>
        <p v-html="$t('sparkmembers.contents.views.lives.accordion-informative.description')"></p>
      </b-card>
    </b-collapse>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: true,
    };
  },
};
</script>
<style lang="scss" scoped>
/deep/ .card-body {
  padding-top: 0;
}
::v-deep .green-text {
  color: #348535;
}
</style>
